export const ROUTES = {
  home: '/',
  consent: '/consent',
  policy: '/policy',
  researchJune: '/research-june',
  products: '/products',
  product: '/products/:name',
};

export const API_URL = 'https://samolet10d.ru';

export const SUBJECTS = {
  newRequest: 'Новая заявка',
  requestResearch: 'Заявка на исследование',
};

export const METRIKA_COUNTER = 96862450;
export const METRIKA_GOALS = {
  openPopupForm: 'open_popup_form',
  submitPopupForm: 'submit_popup_form',
  submitFooterForm: 'submit_footer_form',
};
