import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import Pdf from '@pages/products/images/pdf.svg';
import { matchingProduct, registry } from '@pages/products/mock';
import { Layout } from '@shared/ui/layout';
import '@pages/product/ui/index.css';

export const Product = () => {
  const { name } = useParams();

  const onDownloadPdf = useCallback(() => {
    // Логика для скачивания файлов
  }, []);

  const product = matchingProduct(name);

  if (!product) return null;

  return (
    <Layout>
      <section className='product-frame'>
        <div className='product'>
          <div className='product-name'>
            <img src={product.logo} alt='лого продукта' loading='lazy' />
            <h3>{product.name}</h3>
          </div>
          <p>Цифровой опыт сотрудника. Развитие, кадровые сервисы, управление командой</p>
          <p>
            Продукт зарегистрирован в{' '}
            <a href='https://reestr.digital.gov.ru/'>
              Едином реестре российских программ{' '}
            </a>
            для ЭВМ и баз данных. Реестровая запись:
            <span className='product-record'>{registry}</span>
          </p>
        </div>
        <div className='manual'>
          <div className='manual-item'>
            <img className='manual-img' src={Pdf} alt='скачать пдф' loading='lazy' />
            <div>
              <h6>Описание функциональных характеристик</h6>
              <button className='download' onClick={onDownloadPdf}>
                Скачать документ
              </button>
            </div>
          </div>
          <div className='manual-item'>
            <img src={Pdf} alt='скачать пдф' loading='lazy' />
            <div>
              <h6>Руководство по установке</h6>
              <button className='download' onClick={onDownloadPdf}>
                Скачать документ
              </button>
            </div>
          </div>
          <div className='manual-item'>
            <img src={Pdf} alt='скачать пдф' loading='lazy' />
            <div>
              <h6>Руководство по эксплуатации</h6>
              <button className='download' onClick={onDownloadPdf}>
                Скачать документ
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
