import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ScrollIntoView from 'react-scroll-into-view';

import { ROUTES } from '@shared/config';
import { ModalContext } from '@shared/ui/layout/context.ts';

import Logo from '/images/logo.svg';
import '@shared/ui/navigation/index.css';

const centerScrollOptions: ScrollIntoViewOptions = { block: 'center' };

export const Navigation = () => {
  const { onOpenModal } = useContext(ModalContext);

  useEffect(() => {
    const hash = location.hash;

    if (hash) {
      const element = document.getElementById(hash.substring(1));

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <nav className='navigation'>
      <Link to={ROUTES.home}>
        <img src={Logo} alt='logo' />
      </Link>
      <div>
        <ScrollIntoView selector='#aboutSystem' scrollOptions={centerScrollOptions}>
          <Link className='link' to={`${ROUTES.home}#aboutSystem`}>
            <span className='text-14'>О системе</span>
          </Link>
        </ScrollIntoView>
        <ScrollIntoView selector='#solutions' scrollOptions={centerScrollOptions}>
          <Link className='link' to={`${ROUTES.home}#solutions`}>
            <span className='text-14'>Решения</span>
          </Link>
        </ScrollIntoView>
        <ScrollIntoView selector='#contacts' scrollOptions={centerScrollOptions}>
          <Link className='link' to={`${ROUTES.home}#contacts`}>
            <span className='text-14'>Контакты</span>
          </Link>
        </ScrollIntoView>
        <button className='text-14' onClick={onOpenModal}>
          Заказать демо
        </button>
      </div>
    </nav>
  );
};
