import Blueprint from '@pages/products/images/blueprint.svg';
import Catalog from '@pages/products/images/catalog.svg';
import Center from '@pages/products/images/center.svg';
import Chart from '@pages/products/images/chart.svg';
import Contracts from '@pages/products/images/contracts.svg';
import Control from '@pages/products/images/control.svg';
import Cost from '@pages/products/images/cost.svg';
import Docs from '@pages/products/images/docs.svg';
import Fine from '@pages/products/images/fine.svg';
import Home from '@pages/products/images/home.svg';
import KEK from '@pages/products/images/kek.svg';
import Materials from '@pages/products/images/maretials.svg';
import Pass from '@pages/products/images/pass.svg';
import Plan from '@pages/products/images/plan.svg';
import Progress from '@pages/products/images/progress.svg';
import Project from '@pages/products/images/project.svg';
import Roomer from '@pages/products/images/roomer.svg';
import Security from '@pages/products/images/security.svg';
import SMR from '@pages/products/images/smr.svg';
import Team from '@pages/products/images/team.svg';
import Tender from '@pages/products/images/tender.svg';
import Volume from '@pages/products/images/volume.svg';

const overlay =
  'Продукт зарегистрирован в Едином реестре российских программ для ЭВМ и баз данных. Реестровая запись: №18303 от 12.07.2023';

export const mock = [
  { name: 'S.Team', logo: Team, overlay, url: 'team' },
  { name: 'S.Materials', logo: Materials, overlay, url: 'materials' },
  { name: 'S.Center', logo: Center, overlay, url: 'center' },
  { name: 'S.Tender', logo: Tender, overlay, url: 'tender' },
  { name: 'S.SMR', logo: SMR, overlay, url: 'smr' },
  { name: 'S.Progress', logo: Progress, overlay, url: 'progress' },
  { name: 'S.Chart', logo: Chart, overlay, url: 'chart' },
  { name: 'S.Control', logo: Control, overlay, url: 'control' },
  { name: 'S.Home', logo: Home, overlay, url: 'home' },
  { name: 'S.Project', logo: Project, overlay, url: 'project' },
  { name: 'Roomer', logo: Roomer, overlay, url: 'roomer' },
  { name: 'S.KEK', logo: KEK, overlay, url: 'kek' },
  { name: 'S.Cost', logo: Cost, overlay, url: 'cost' },
  { name: 'S.Volume', logo: Volume, overlay, url: 'volume' },
  { name: 'S.Contracts', logo: Contracts, overlay, url: 'contracts' },
  { name: 'S.Pass', logo: Pass, overlay, url: 'pass' },
  { name: 'S.Docs', logo: Docs, overlay, url: 'docs' },
  { name: 'S.Security', logo: Security, overlay, url: 'security' },
  { name: 'S.Blueprint', logo: Blueprint, overlay, url: 'blueprint' },
  { name: 'S.Fine', logo: Fine, overlay, url: 'fine' },
  { name: 'S.Plan', logo: Plan, overlay, url: 'plan' },
  { name: 'S.Catalog', logo: Catalog, overlay, url: 'catalog' },
];

export const registry = '№14757 от 05.09.2022';

export const matchingProduct = (name: string | undefined) => {
  return mock.find(product => product.url === name);
};
