import { useMemo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Info from '@pages/products/images/info.svg';
import { mock } from '@pages/products/mock';
import { Layout } from '@shared/ui/layout';
import '@pages/products/ui/index.css';

export const Products = () => {
  const products = useMemo(
    () =>
      mock.map((product, index) => (
        <Link className='product' key={index} to={product.url}>
          <img src={product.logo} alt='product' loading='lazy' />
          <span className='product-name'>{product.name}</span>
          <OverlayTrigger placement='top' overlay={<Tooltip>{product.overlay}</Tooltip>}>
            <img className='info' src={Info} alt='info' loading='lazy' />
          </OverlayTrigger>
        </Link>
      )),
    [],
  );

  return (
    <Layout>
      <section className='products-frame'>
        <div className='content'>
          <h3>Программные продукты</h3>
          <div className='products-wrapper'>{products}</div>
          <p className='price-description'>
            Стоимость использования программных продуктов рассчитывается индивидуально.
            Для расчета стоимости вы можете связаться с нами по электронной почте{' '}
            <a className='price-mail' href='mailto:smlttech@samolet.ru' target='blank'>
              smlttech@samolet.ru
            </a>
          </p>
        </div>
      </section>
    </Layout>
  );
};
