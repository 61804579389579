import { createContext, Dispatch, FormEvent, SetStateAction } from 'react';

import { FormInitialState } from '@shared/hooks/form.tsx';
import { FileNames, TextTypes } from '@shared/ui/modalForm/enum.ts';

export const ModalContext = createContext<{
  isSuccessSend: boolean | null;
  modalType: TextTypes;
  formInputs: JSX.Element[];
  formCheckboxes: JSX.Element[];
  setIsSuccessSend: Dispatch<SetStateAction<boolean | null>>;
  setFormState: Dispatch<SetStateAction<FormInitialState>>;
  setModalType: Dispatch<SetStateAction<TextTypes>>;
  setFileName: Dispatch<SetStateAction<null | FileNames>>;
  onOpenModal: () => void;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
}>({
  isSuccessSend: null,
  modalType: TextTypes.requestDemo,
  formInputs: [],
  formCheckboxes: [],
  setIsSuccessSend: () => {},
  setFormState: () => {},
  setModalType: () => {},
  setFileName: () => {},
  onOpenModal: () => {},
  onSubmit: () => {},
});
