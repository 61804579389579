import classNames from 'classnames';
import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import sbjs from 'sourcebuster';

import { apiClient } from '@shared/api';
import { METRIKA_COUNTER } from '@shared/config';
import { FileNames } from '@shared/ui/modalForm/enum.ts';

export type FormInitialState = {
  [key: string]: {
    type: 'text' | 'checkbox';
    placeholder?: string;
    value: string | boolean;
    error: boolean;
  };
};

export const formInitialState: FormInitialState = {
  fio: {
    type: 'text',
    placeholder: 'ФИО',
    value: '',
    error: false,
  },
  company: {
    type: 'text',
    placeholder: 'Компания',
    value: '',
    error: false,
  },
  telephone: {
    type: 'text',
    placeholder: 'Телефон',
    value: '',
    error: false,
  },
  email: {
    type: 'text',
    placeholder: 'Почта',
    value: '',
    error: false,
  },
  consentToProcessing: {
    type: 'checkbox',
    placeholder: 'Согласие на обработку персональных данных',
    value: false,
    error: false,
  },
};

export const useForm = ({
  subject,
  fileName,
}: {
  subject?: string;
  fileName: FileNames | null;
}) => {
  const [formState, setFormState] = useState(formInitialState);
  const [isSuccessSend, setIsSuccessSend] = useState<null | boolean>(null);

  const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setFormState(prevState => {
      return {
        ...prevState,
        [event.target.name as keyof FormInitialState]: {
          ...prevState[event.target.name],
          value:
            event.target.type === 'checkbox' ? event.target.checked : event.target.value,
          error: false,
        },
      };
    });
  }, []);

  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      setFormState(prevState => {
        const afterValidateFormState: FormInitialState = {
          ...prevState,
          ...Object.keys(prevState).reduce((newState, key) => {
            return {
              ...newState,
              [key]: {
                ...prevState[key],
                value: prevState[key].value,
                error:
                  (prevState[key].value as string).length === 0 || !prevState[key].value,
              },
            };
          }, {}),
        };

        if (Object.values(afterValidateFormState).every(item => !item.error)) {
          const data = Object.keys(afterValidateFormState).reduce(
            (formForSend, key) => {
              formForSend[key] = afterValidateFormState[key].value;

              return formForSend;
            },
            {} as Record<string, unknown>,
          );

          data.mdm = `${sbjs.get.current.src}/${sbjs.get.current.mdm}`;
          data.subject = subject;

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ym(METRIKA_COUNTER, 'getClientID', (clientID: number) => {
            data.ymClientId = clientID;
          });

          console.debug('Отправляем:', data);
          console.debug('Имя файла:', fileName);

          apiClient
            .post('/api/index.php', data)
            .then(() => {
              setIsSuccessSend(true);

              if (fileName) {
                window.open(`/pdf/${fileName}`, '_blank')!.focus();
              }
            })
            .catch(() => setIsSuccessSend(false));
        }

        return afterValidateFormState;
      });
    },
    [fileName, subject],
  );

  const formInputs = useMemo(() => {
    return Object.keys(formInitialState)
      .filter(item => formInitialState[item].type === 'text')
      .map((key, index, items) => {
        return (
          <input
            key={key}
            className={classNames({
              'mb-16': index !== items.length - 1,
              'mb-24': index === items.length - 1,
              'error-input': formState[key].error,
            })}
            name={key}
            placeholder={formState[key].placeholder}
            value={formState[key].value as string}
            onChange={onInputChange}
          />
        );
      });
  }, [formState, onInputChange]);

  const formCheckboxes = useMemo(() => {
    return Object.keys(formInitialState)
      .filter(item => formInitialState[item].type === 'checkbox')
      .map(key => {
        return (
          <input
            key={key}
            className={classNames({
              'error-input': formState.consentToProcessing.error,
            })}
            type='checkbox'
            name={key}
            checked={formState.consentToProcessing.value as boolean}
            onChange={onInputChange}
          />
        );
      });
  }, [formState, onInputChange]);

  return {
    setFormState,
    isSuccessSend,
    setIsSuccessSend,
    formInputs,
    formCheckboxes,
    onSubmit,
  };
};
