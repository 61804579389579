import { StrictMode, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Consent } from '@pages/consent/ui';
import { Home } from '@pages/home/ui';
import { Policy } from '@pages/policy/ui';
import { Product } from '@pages/product/ui';
import { Products } from '@pages/products/ui';
import { ResearchJune } from '@pages/researchJune/ui';
import { ROUTES } from '@shared/config';
import 'bootstrap/dist/css/bootstrap.min.css';
import './global.css';

export const App = () => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require('sourcebuster').init();
  }, []);

  return (
    <StrictMode>
      <Routes>
        <Route path={ROUTES.home} Component={Home} />
        <Route path={ROUTES.consent} Component={Consent} />
        <Route path={ROUTES.policy} Component={Policy} />
        <Route path={ROUTES.researchJune} Component={ResearchJune} />
        <Route path={ROUTES.products} Component={Products} />
        <Route path={ROUTES.product} Component={Product} />
      </Routes>
    </StrictMode>
  );
};
